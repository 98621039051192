<div class="sidebar-wrapper">
  <div class="logo">
    <div class="logo-image-small">
      @if (sidebarToggle) {
        <img class="img-logo" [src]="logo" />
      }
      @if (!sidebarToggle) {
        <img src="assets/img/logo_iso.png" />
      }
    </div>
  </div>
  @if (sidebarToggle) {
    <ul class="nav" ngbAccordion>
      @for (menuItem of sidebar?.routes; track menuItem; let index = $index) {
        @if (isVisible(menuItem)) {
          <li
            class="{{ menuItem.class }}"
            ngbAccordionItem
            [collapsed]="menuItem.collapsed">
            <div ngbAccordionHeader>
              <button ngbAccordionButton (click)="onMenuClick(index)">
                <small
                  ><strong class="text-uppercase">{{
                    menuItem.title | translate
                  }}</strong></small
                >
              </button>
            </div>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  @for (route of menuItem.routes; track route) {
                    @if (isVisible(route)) {
                      <a
                        [routerLink]="[route.path]"
                        (click)="onPathClick()"
                        [ngClass]="{ active: isCurrentTypeUrl(route.type) }">
                        <i class="{{ route.icon }}"></i>
                        {{ route.title | translate }}
                      </a>
                    }
                  }
                </ng-template>
              </div>
            </div>
          </li>
        }
      }
    </ul>
  } @else {
    <ul class="nav">
      @for (menuItem of sidebar?.routes; track menuItem) {
        @if (isVisible(menuItem)) {
          @for (route of menuItem.routes; track route) {
            @if (isVisible(route)) {
              <li [ngClass]="{ active: isCurrentTypeUrl(route.type) }">
                <a
                  [routerLink]="[route.path]"
                  (click)="onPathClick()"
                  [ngbTooltip]="route.title | translate"
                  placement="end"
                  tooltipClass="custom-class"
                  container="body"
                  triggers="hover">
                  <i class="{{ route.icon }}"></i>
                </a>
              </li>
            }
          }
        }
      }
    </ul>
  }
</div>
