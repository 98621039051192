import { HttpErrorResponse } from '@angular/common/http';

import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { ModalServerErrorComponent } from '@app/shared/components/modals/server-error/server-error.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { of, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ServerErrorService {
  constructor(
    private ngbModal: NgbModal,
    private router: Router
  ) {}

  catchErrorAndOpenModal<T>(
    throwAgain: boolean = false,
    returnValue: T | null = null,
    redirect?: boolean
  ) {
    return catchError((response: HttpErrorResponse) => {
      /// * Prevent ExpressionChangedAfterItHasBeenCheckedError
      const activeElement = document.activeElement as HTMLElement;
      activeElement.blur.apply(activeElement);
      const modal = this.ngbModal.open(ModalServerErrorComponent, {
        backdrop: 'static',
        centered: true,
      });
      modal.componentInstance.response = response;
      if (redirect) {
        this.router.navigate(['/', 'redirect']);
      }
      return throwAgain ? throwError(response) : of(returnValue);
    });
  }
}
