import { CommonModule, Location } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { ROLE } from '@app/data/enums/role';
import { ICompanyUser, IUser } from '@app/data/models/IUser';
import { CompanyService } from '@app/shared/services/company.service';
import { AuthenticationService } from '@core/services/authentication.service';
import { I18nService } from '@core/services/i18n.service';
import { environment } from '@env';
import { ICompany } from '@models/ICompany';
import { ICriteriaResponse } from '@models/ICriteriaResponse';
import {
  NgbModal,
  NgbCollapse,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ToastrService } from 'ngx-toastr';
import { filter, from, tap } from 'rxjs';
import { RouteInfo, SIDEBAR_MENU } from '../sidebar/data/sidebar-menu';
import { ModalUserProfileComponent } from './components/modal-user-profile/modal-user-profile.component';

@UntilDestroy()
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'pae-navbar-cmp',
  templateUrl: 'navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgbCollapse,
    FormsModule,
    SelectDropDownModule,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownItem,
    TranslateModule,
  ],
})
export class NavbarComponent implements OnInit {
  @ViewChild('navbar-cmp', { static: false }) button: any;
  @Output() sidebarToggleEvent = new EventEmitter<boolean>();
  isCollapsed = true;
  searchInput = '';
  companies: ICompany[] = [];
  companySelected: ICompany = null;
  config = {
    search: true,
    placeholder: this.translate.instant('NAVBAR.SELECT_COMPANY'),
    searchPlaceholder: this.translate.instant('GENERAL.SEARCH'),
    clearOnSelection: true,
    displayKey: 'name',
    searchOnKey: 'name',
    displayFn: (item: ICompany) => {
      return item.name;
    },
  } as any;
  companyUser: ICompanyUser = null;
  userMe: IUser = null;
  environment = environment;
  private listTitles: any[];
  private nativeElement: Node;
  private toggleButton: any;
  private sidebarVisible: boolean;

  constructor(
    private location: Location,
    private element: ElementRef,
    private router: Router,
    private $auth: AuthenticationService,
    private $company: CompanyService,
    private i18nService: I18nService,
    private confirmationDialogService: ConfirmationDialogService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private $api: ApiService,
    private $toastr: ToastrService
  ) {
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit(): void {
    this.listTitles = SIDEBAR_MENU.routes
      .map((route: RouteInfo) => route.routes)
      .reduce((accesor: RouteInfo[], routes: RouteInfo[]) => accesor.concat(routes), []);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.$company.refreshCompanies$
      .pipe(
        untilDestroyed(this),
        tap((value: boolean) => {
          if (value) {
            this.getCompanies();
          }
        })
      )
      .subscribe();
    this.$api.user$
      .pipe(
        untilDestroyed(this),
        filter((userMe: IUser) => !!userMe),
        tap((userMe: IUser) => {
          this.userMe = userMe;
          if (this.userMe.superadmin) {
            this.getCompanies();
          } else {
            this.setUserCompanies();
          }
          if (this.userMe && this.companySelected) {
            this.setCompanyUser();
          }
        })
      )
      .subscribe();
  }

  getTitle(): string {
    let url = this.location.prepareExternalUrl(this.location.path());
    if (url.startsWith('#')) {
      url = url.slice(1);
    }
    url = url.split('?')[0];
    for (const element of this.listTitles) {
      if (this.searchTerm(element.type, url)) {
        return element.title;
      }
    }
    return 'Dashboard';
  }

  sidebarToggle(): void {
    if (window.innerWidth > 991) {
      this.sidebarToggleEvent.emit(true);
      return;
    }
    if (!this.sidebarVisible) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  sidebarOpen(): void {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);

    html.classList.add('nav-open');
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    this.sidebarVisible = true;
  }

  sidebarClose(): void {
    if (environment.machineryGUI) {
      return;
    }
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  }

  collapse(): void {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }
  }

  logout(): void {
    this.confirmationDialogService
      .confirm(
        this.translate.instant('NAVBAR.LOGOUT'),
        this.translate.instant('FORMS.ARE_YOU_SURE'),
        this.translate.instant('GENERAL.ACCEPT'),
        this.translate.instant('GENERAL.CANCEL')
      )
      .then((confirmation: boolean) => {
        if (confirmation) {
          this.$auth.logout().then(() => {
            this.router.navigate(['/', 'session']);
          });
        }
      });
  }

  changeLanguage(): void {
    const current = this.i18nService.language;

    if (current === 'es-ES') {
      this.i18nService.language = 'en-EN';
    } else {
      this.i18nService.language = 'es-ES';
    }
  }

  openUserProfileModal(): void {
    const modalRef = this.modalService.open(ModalUserProfileComponent, {
      centered: true,
    });
    modalRef.componentInstance.companyUser = this.companyUser;
  }

  selectionChanged($event: { value: ICompany }): void {
    if (
      !$event.value ||
      (Array.isArray($event.value) && ($event.value as any[]).length === 0)
    ) {
      this.selectCompany();
      return;
    }
    this.$company.company = $event.value;
    this.setCompanyUser();
  }

  private getCompanies(): void {
    this.companies = [];
    this.$company
      .getAll()
      .pipe(
        untilDestroyed(this),
        tap((response: ICriteriaResponse<ICompany>) => {
          this.companies = response.body;
          if (this.companies.length) {
            this.selectCompany();
          }
        })
      )
      .subscribe();
  }

  private selectCompany(): void {
    from(this.$company.getStoredCompanySelected())
      .pipe(
        untilDestroyed(this),
        tap((company: ICompany) => {
          let selectedCompany = null;
          if (company) {
            for (const _company of this.companies) {
              if (company.id === _company.id) {
                selectedCompany = _company;
              }
            }
            if (!selectedCompany) {
              selectedCompany = this.companies[0];
            }
            this.companySelected = selectedCompany;
          } else {
            selectedCompany = this.companies[0];
            this.companySelected = selectedCompany;
          }
          if (this.userMe) {
            if (this.userMe.superadmin) {
              this.$api.setRole$(ROLE.CONSULTANT, true);
            } else {
              this.setCompanyUser();
            }
          }
          this.$company.company = selectedCompany;
        })
      )
      .subscribe();
  }

  private setUserCompanies(): void {
    this.companies = [];
    this.companies = this.userMe.companyUsers
      .filter(
        (companyUser: ICompanyUser) =>
          (companyUser.employee &&
            !companyUser.employee.isDeleted &&
            !companyUser.employee.isDisabled) ||
          companyUser.role === ROLE.ADMINISTRATOR
      )
      .map((companyUser: ICompanyUser) => companyUser.company);
    if (this.companies.length) {
      this.selectCompany();
    } else {
      this.$auth.logout().then(() => {
        this.$toastr.error(this.translate.instant('GENERAL.NO_COMPANY'));
        this.router.navigate(['/', 'session']);
      });
    }
  }

  private setCompanyUser(): void {
    if (!this.userMe || this.userMe.superadmin) {
      return;
    }
    for (const companyUser of this.userMe.companyUsers) {
      if (companyUser.companyId === this.companySelected.id) {
        if (companyUser.companyId === this.companySelected.id) {
          this.$api.setRole$(companyUser.role as ROLE, true);
        }
        if (companyUser.role === ROLE.EMPLOYEE || companyUser.role === ROLE.MANAGER) {
          this.companyUser = companyUser;
          this.$api.setCompanyUser$(companyUser, true);
        }
      }
    }
  }

  private searchTerm(term: string, url: string): boolean {
    const termScaped = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    const regex = new RegExp(`/${termScaped}/`, 'i');

    return regex.test(url);
  }
}
