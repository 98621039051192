import { ROLE } from '@app/data/enums/role';

export const SIDEBAR_VERSION = '4.0.0';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  type: string;
  role: string[];
  routes?: RouteInfo[];
  collapsed?: boolean;
}

export interface SidebarMenu {
  version: string;
  routes: RouteInfo[];
}

export const SIDEBAR_MENU: SidebarMenu = {
  version: SIDEBAR_VERSION,
  routes: [
    {
      path: '',
      title: 'Dashboard',
      icon: '',
      class: '',
      type: 'group',
      role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT, ROLE.EMPLOYEE, ROLE.MANAGER],
      routes: [
        {
          path: '/dashboard',
          title: 'Dashboard',
          icon: 'fas fa-tachometer-alt',
          class: '',
          type: 'dashboard',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT, ROLE.EMPLOYEE, ROLE.MANAGER],
        },
      ],
      collapsed: false,
    },
    {
      path: '',
      title: 'SIDEBAR.GENERAL',
      icon: '',
      class: '',
      type: 'group',
      role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
      routes: [
        {
          path: '/company/list',
          title: 'SIDEBAR.COMPANY',
          icon: 'nc-icon nc-globe',
          class: '',
          type: 'company',
          role: [ROLE.CONSULTANT],
        },
        {
          path: '/sector/list',
          title: 'SIDEBAR.SECTOR',
          icon: 'fas fa-vector-square',
          class: '',
          type: 'sector',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/business-line/list',
          title: 'SIDEBAR.BUSINESS_LINE',
          icon: 'fas fa-network-wired',
          class: '',
          type: 'business-line',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/incident-type/list',
          title: 'SIDEBAR.INCIDENT',
          icon: 'fas fa-exclamation-triangle',
          class: '',
          type: 'incident-type',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/work-type/list',
          title: 'SIDEBAR.WORK_TYPE',
          icon: 'fas fa-cogs',
          class: '',
          type: 'work-type',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/client-type/list',
          title: 'SIDEBAR.CLIENT_TYPE',
          icon: 'fas fa-users',
          class: '',
          type: 'client-type',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/process-type/list',
          title: 'SIDEBAR.PROCESS_TYPE',
          icon: 'fas fa-microchip',
          class: '',
          type: 'process-type',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/units/list',
          title: 'SIDEBAR.UNITS',
          icon: 'fas fa-weight',
          class: '',
          type: 'units',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
      ],
      collapsed: true,
    },
    {
      path: '',
      title: 'SIDEBAR.MANAGEMENT',
      icon: '',
      class: '',
      type: 'group',
      role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT, ROLE.MANAGER, ROLE.EMPLOYEE],
      routes: [
        {
          path: '/unit-rate/list',
          title: 'SIDEBAR.UNIT_RATE',
          icon: 'fas fa-coins',
          class: '',
          type: 'unit-rate',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/client/list',
          title: 'SIDEBAR.CLIENT',
          icon: 'fas fa-building',
          class: '',
          type: 'client',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/project/list',
          title: 'SIDEBAR.PROJECT',
          icon: 'fas fa-project-diagram',
          class: '',
          type: 'project',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT, ROLE.MANAGER],
        },
        {
          path: '/dossier/list',
          title: 'SIDEBAR.DOSSIER',
          icon: 'fas fa-folder',
          class: '',
          type: 'dossier',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT, ROLE.MANAGER],
        },
        {
          path: '/process/list',
          title: 'SIDEBAR.PROCESS',
          icon: 'fas fa-wave-square',
          class: '',
          type: 'process',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/hour-tracking/list',
          title: 'SIDEBAR.PRODUCTION',
          icon: 'fas fa-user-clock',
          class: '',
          type: 'hour-tracking',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT, ROLE.EMPLOYEE, ROLE.MANAGER],
        },
        {
          path: '/production-tracking/list',
          title: 'SIDEBAR.PRODUCTION_TRACKING',
          icon: 'fas fa-user-clock',
          class: '',
          type: 'production-tracking',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT, ROLE.EMPLOYEE, ROLE.MANAGER],
        },
      ],
      collapsed: true,
    },
    {
      path: '',
      title: 'SIDEBAR.MACHINERY',
      icon: '',
      class: '',
      type: 'group',
      role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT, ROLE.EMPLOYEE, ROLE.MANAGER],
      routes: [
        {
          path: '/section/list',
          title: 'SIDEBAR.SECTIONS',
          icon: 'fas fa-directions',
          class: '',
          type: 'section',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/machine/list',
          title: 'SIDEBAR.MACHINES',
          icon: 'fas fa-desktop',
          class: '',
          type: 'machine',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/task/list',
          title: 'SIDEBAR.TASK',
          icon: 'fas fa-file-pen',
          class: '',
          type: 'task',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/subprocess/list',
          title: 'SIDEBAR.SUBPROCESS',
          icon: 'fas fa-gears',
          class: '',
          type: 'subprocess',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/reference/list',
          title: 'SIDEBAR.REFERENCE',
          icon: 'fas fa-arrows-rotate',
          class: '',
          type: 'reference',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/incident-type-maq/list',
          title: 'SIDEBAR.INCIDENT_TYPE',
          icon: 'fas fa-triangle-exclamation',
          class: '',
          type: 'incident-type-maq',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/dossier-machinery/list',
          title: 'SIDEBAR.DOSSIER',
          icon: 'fas fa-folder',
          class: '',
          type: 'dossier-machinery',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/work-order/',
          title: 'SIDEBAR.WORK_ORDER',
          icon: 'fas fa-solid fa-users-gear',
          class: '',
          type: 'work-order',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT, ROLE.EMPLOYEE, ROLE.MANAGER],
        },
      ],
      collapsed: true,
    },
    {
      path: '',
      title: 'SIDEBAR.PERSONAL',
      icon: '',
      class: '',
      type: 'group',
      role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
      routes: [
        {
          path: '/employee/list',
          title: 'SIDEBAR.EMPLOYEE',
          icon: 'fas fa-user',
          class: '',
          type: 'employee',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/administrator/list',
          title: 'SIDEBAR.ADMIN',
          icon: 'fas fa-user-tie',
          class: '',
          type: 'administrator',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/consultant/list',
          title: 'SIDEBAR.CONSULTANT',
          icon: 'fas fa-user-shield',
          class: '',
          type: 'consultant',
          role: [ROLE.CONSULTANT],
        },
      ],
      collapsed: true,
    },
    {
      path: '',
      title: 'SIDEBAR.REPORTS',
      icon: '',
      class: '',
      type: 'group',
      role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
      routes: [
        {
          path: '/excels/list',
          title: 'SIDEBAR.EXCELS',
          icon: 'fas fa-file-excel',
          class: '',
          type: 'excels',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
      ],
      collapsed: true,
    },
    /* {
      path: '',
      title: 'SIDEBAR.COSTS',
      icon: '',
      class: '',
      type: 'group',
      role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
      routes: [
        {
          path: '/pgc/list',
          title: 'SIDEBAR.GAP',
          icon: 'fas fa-file-invoice-dollar',
          class: '',
          type: 'pgc',
          role: [ROLE.CONSULTANT],
        },
        {
          path: '/groups-poma/list',
          title: 'SIDEBAR.GROUPS_POMA',
          icon: 'fas fa-address-card',
          class: '',
          type: 'groups-poma',
          role: [ROLE.CONSULTANT],
        },
        {
          path: '/company-group/list',
          title: 'SIDEBAR.GROUP_ACCOUNTS',
          icon: 'fas fa-folder-tree',
          class: '',
          type: 'company-group',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/distribution-pc/list',
          title: 'SIDEBAR.DISTRIBUTION',
          icon: 'fas fa-percent',
          class: '',
          type: 'distribution-pc',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/accounts/list',
          title: 'SIDEBAR.ACCOUNTS',
          icon: 'fas fa-briefcase',
          class: '',
          type: 'accounts',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },

        {
          path: '/journal-entry/list',
          title: 'SIDEBAR.JOURNAL_ENTRY',
          icon: 'fas fa-file-pen',
          class: '',
          type: 'journal-entry',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/je-adjustments/list',
          title: 'TABLES.JE_ADJUSTMENTS',
          icon: 'fas fa-pen',
          class: '',
          type: 'je-adjustments',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/accounts-pyg/list',
          title: 'SIDEBAR.ACCOUNTS_PyG',
          icon: 'fas fa-file-invoice',
          class: '',
          type: 'accounts-pyg',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/balances-pyg/list',
          title: 'SIDEBAR.BALANCE_PyG',
          icon: 'fas fa-euro-sign',
          class: '',
          type: 'balances-pyg',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/budget-balances/list',
          title: 'TABLES.BUDGET_BALANCE',
          icon: 'fas fa-money-bill',
          class: '',
          type: 'budget-balances',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/balance/list',
          title: 'SIDEBAR.BALANCE',
          icon: 'fas fa-sack-dollar',
          class: '',
          type: 'balance',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
      ],
      collapsed: true,
    },
    {
      path: '',
      title: 'SIDEBAR.COSTS_ADVANCED',
      icon: '',
      class: '',
      type: 'group',
      role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
      routes: [
        {
          path: '/ledger/list',
          title: 'SIDEBAR.EXERCISE',
          icon: 'fas fa-book',
          class: '',
          type: 'ledger',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
        {
          path: '/account-modifier/list',
          title: 'SIDEBAR.ACCOUNTS_MODIFIER',
          icon: 'fas fa-user-pen',
          class: '',
          type: 'account-modifier',
          role: [ROLE.ADMINISTRATOR, ROLE.CONSULTANT],
        },
      ],
      collapsed: true,
    }, */
    {
      path: '',
      title: 'SIDEBAR.IMPORTS',
      icon: '',
      class: '',
      type: 'group',
      role: [ROLE.CONSULTANT],
      routes: [
        {
          path: '/dossier-imports/list',
          title: 'SIDEBAR.DOSSIER',
          icon: 'fas fa-folder',
          class: '',
          type: 'dossier-imports',
          role: [ROLE.CONSULTANT],
        },
        {
          path: '/hour-tracking-imports/list',
          title: 'SIDEBAR.PRODUCTION',
          icon: 'fas fa-user-clock',
          class: '',
          type: 'hour-tracking-imports',
          role: [ROLE.CONSULTANT],
        },
        {
          path: '/dossier-crm-imports/list',
          title: 'SIDEBAR.DOSSIER_CRM',
          icon: 'fas fa-folder',
          class: '',
          type: 'dossier-crm-imports',
          role: [ROLE.CONSULTANT],
        },
        {
          path: '/accounting-imports/list',
          title: 'SIDEBAR.COSTS',
          icon: 'fas fa-calculator',
          class: '',
          type: 'accounting-imports',
          role: [ROLE.CONSULTANT],
        },
      ],
      collapsed: true,
    },
    {
      path: '',
      title: 'SIDEBAR.ACCESSES_API',
      icon: '',
      class: '',
      type: 'group',
      role: [ROLE.CONSULTANT],
      routes: [
        {
          path: '/api-key/list',
          title: 'SIDEBAR.API',
          icon: 'fa-solid fa-key',
          class: '',
          type: 'api-key',
          role: [ROLE.CONSULTANT],
        },
      ],
      collapsed: true,
    },
  ],
};
