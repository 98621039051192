import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, NgModule } from '@angular/core';
import * as LocalForage from 'localforage';

/// <reference types="localforage" />
var DatabaseDriver;
(function (DatabaseDriver) {
  DatabaseDriver["INDEXEDDB"] = "indexeddb";
  DatabaseDriver["LOCALSTORAGE"] = "localstorage";
  DatabaseDriver["WEBSQL"] = "websql";
})(DatabaseDriver || (DatabaseDriver = {}));
/**
 * @internal
 */
function getDefaultConfig() {
  return {
    driverOrder: [DatabaseDriver.INDEXEDDB, DatabaseDriver.WEBSQL, DatabaseDriver.LOCALSTORAGE],
    name: '_database',
    storeName: '_store'
  };
}
/**
 * @internal
 */
function getDriverOrder(driverOrder) {
  return driverOrder.map(driver => {
    switch (driver) {
      case DatabaseDriver.INDEXEDDB:
        return LocalForage.INDEXEDDB;
      case DatabaseDriver.WEBSQL:
        return LocalForage.WEBSQL;
      case DatabaseDriver.LOCALSTORAGE:
        return LocalForage.LOCALSTORAGE;
      default:
        return null;
    }
  });
}
class Database {
  /////////////////
  // Constructor //
  /////////////////
  constructor(config, defaults = {}) {
    this.defaults = defaults;
    this._driver = null;
    this._dbPromise = new Promise((resolve, reject) => {
      let db;
      const actualConfig = {
        ...getDefaultConfig(),
        ...config
      };
      db = LocalForage.createInstance(actualConfig);
      db.setDriver(getDriverOrder(actualConfig.driverOrder)).then(() => {
        this._driver = db.driver();
        this.mergeDefaults();
      }).then(() => resolve(db)).catch(e => reject(e));
    });
  }
  ///////////////////////
  // Private Functions //
  ///////////////////////
  /// code...
  /////////////////////////
  // Protected Functions //
  /////////////////////////
  async mergeDefaults() {
    const queue = [];
    const keys = await this.keys();
    for (const k of Object.keys(this.defaults)) {
      if (keys.indexOf(k) >= 0) {
        queue.push(this.set(k, this.defaults[k]));
      }
    }
    return await Promise.all(queue).then(() => {});
  }
  ///////////////////////
  // GET/SET Functions //
  ///////////////////////
  get driver() {
    return this._driver;
  }
  //////////////////////
  // Public Functions //
  //////////////////////
  async clear() {
    const db = await this._dbPromise;
    return await db.clear();
  }
  async forEach(iteratee) {
    const db = await this._dbPromise;
    return await db.iterate(iteratee);
  }
  async get(key) {
    const db = await this._dbPromise;
    return await db.getItem(key);
  }
  async keys() {
    const db = await this._dbPromise;
    return await db.keys();
  }
  async length() {
    const db = await this._dbPromise;
    return await db.length();
  }
  async ready() {
    return await this._dbPromise;
  }
  async remove(key) {
    const db = await this._dbPromise;
    return await db.removeItem(key);
  }
  async set(key, value) {
    const db = await this._dbPromise;
    return await db.setItem(key, value);
  }
}
const DB_DEFAULTS = new InjectionToken('DB_DEFAULTS');
class DatabaseProvider {
  /////////////////
  // Constructor //
  /////////////////
  /**
   * The first database will be set as default.
   *
   * @param databases `{ [key: string]: IDatabase; }`
   */
  constructor(databases) {
    /**
     * Collection of registered databases. **Don't modify it directly!**
     *
     * @internal
     */
    this._databases = new Map();
    Object.keys(databases).forEach((k, i) => this.set(k, databases[k], i === 0));
  }
  ///////////////////////
  // Private Functions //
  ///////////////////////
  /// code...
  ///////////////////////
  // GET/SET Functions //
  ///////////////////////
  /**
   * A shorthand for the default registered database.
   */
  get default() {
    return this._databases.get(this._default);
  }
  //////////////////////
  // Public Functions //
  //////////////////////
  /**
   * Removes all registered databases from the collection.
   */
  clear() {
    this._databases.clear();
  }
  /**
   * Removes the specified registered database from the collection.
   *
   * @param key The key of the database to remove from the collection
   * @returns `true` if a registered database in the collection existed and has been removed,
   * or `false` if the database does not exist
   */
  delete(key) {
    return this._databases.delete(key);
  }
  /**
   * Returns the specified registered database from the collection.
   *
   * @param key The key of the database to return from the collection
   * @returns The registered database associated with the specified key or `undefined` if
   * the key can't be found in the collection
   */
  get(key) {
    return this._databases.get(key);
  }
  /**
   * Return a boolean indicating whether a registered database with the specified key exists or
   * not.
   *
   * @param key The key of the database to test for presence in the collection
   * @returns `true` if a registered database with the specified key exists in the collection;
   * otherwise `false`
   */
  has(key) {
    return this._databases.has(key);
  }
  /**
   * Adds a database with a specified key and value to the collection.
   *
   * @param key The key of the database to add to the collection
   * @param value The value of the database to add to the collection
   * @param isDefault Set the registered database as default
   * @returns The registered database just added
   */
  set(key, value, isDefault = false) {
    this._databases.set(key, new Database(value.config, value.defaults));
    if (isDefault || value.isDefault === true) {
      this._default = key;
    }
    return this.get(key);
  }
  toArray() {
    const databases = [];
    this._databases.forEach((database, key) => databases.push({
      key,
      database
    }));
    return databases;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "17.3.11",
      ngImport: i0,
      type: DatabaseProvider,
      deps: [{
        token: DB_DEFAULTS
      }],
      target: i0.ɵɵFactoryTarget.Injectable
    });
  }
  static {
    this.ɵprov = i0.ɵɵngDeclareInjectable({
      minVersion: "12.0.0",
      version: "17.3.11",
      ngImport: i0,
      type: DatabaseProvider
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "17.3.11",
  ngImport: i0,
  type: DatabaseProvider,
  decorators: [{
    type: Injectable
  }],
  ctorParameters: () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DB_DEFAULTS]
    }]
  }]
});
/**
 * Factory of `DatabaseProvider`.
 */
function factory(databases) {
  return new DatabaseProvider(databases);
}
class DatabaseModule {
  static forRoot(config = {}) {
    return {
      ngModule: DatabaseModule,
      providers: [{
        provide: DB_DEFAULTS,
        useValue: config.databases || {}
      }, {
        provide: DatabaseProvider,
        useFactory: factory,
        deps: [DB_DEFAULTS]
      }]
    };
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "17.3.11",
      ngImport: i0,
      type: DatabaseModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "17.3.11",
      ngImport: i0,
      type: DatabaseModule
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "17.3.11",
      ngImport: i0,
      type: DatabaseModule
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "17.3.11",
  ngImport: i0,
  type: DatabaseModule,
  decorators: [{
    type: NgModule
  }]
});

/*
 * Public API Surface of database
 */

/**
 * Generated bundle index. Do not edit.
 */

export { DB_DEFAULTS, DatabaseModule, DatabaseProvider, factory };
