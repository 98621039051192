import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { ICompanyUser } from '@app/data/models/IUser';
import { EmployeeService } from '@app/shared/services/employee.service';
import {
  FileUploadControl,
  FileUploadValidators,
  FileUploadComponent,
} from '@iplab/ngx-file-upload';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, catchError, of, switchMap, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'pae-modal-user-profile',
  templateUrl: './modal-user-profile.component.html',
  styleUrls: ['./modal-user-profile.component.scss'],
  standalone: true,
  imports: [FileUploadComponent, AsyncPipe, TranslateModule],
})
export class ModalUserProfileComponent implements OnInit {
  @Input() companyUser: ICompanyUser = null;
  readonly uploadedFile: BehaviorSubject<string> = new BehaviorSubject(null);
  filesControl = new FileUploadControl(
    {
      accept: ['image/*'],
      multiple: false,
    },
    FileUploadValidators.filesLimit(1)
  );
  imageSrc: string;
  file: File = null;
  loading = false;

  constructor(
    public aModal: NgbActiveModal,
    private $employee: EmployeeService,
    private $api: ApiService
  ) {}

  ngOnInit(): void {
    this.getImage();
    this.imageSrc = this.companyUser.employee?.file?.path;
  }

  closeModal(): void {
    this.aModal.dismiss();
  }

  submit(): void {
    this.loading = true;
    this.$employee
      .addImage(this.companyUser.companyId, this.companyUser.employee.id, this.file)
      .pipe(
        untilDestroyed(this),
        switchMap(() => this.$api.getMe()),
        tap(() => {
          this.loading = false;
          this.aModal.close();
        }),
        this.$employee.$serverError.catchErrorAndOpenModal(true),
        catchError((error: string) => {
          this.loading = false;
          return of(error);
        })
      )
      .subscribe();
  }

  deletePreviewImage(): void {
    this.imageSrc = null;
    this.filesControl.setValue([]);
  }

  deleteImage(): void {
    this.loading = true;
    this.$employee
      .deleteImage(this.companyUser.companyId, this.companyUser.employee.id)
      .pipe(
        untilDestroyed(this),
        switchMap(() => this.$api.getMe()),
        tap(() => {
          this.imageSrc = null;
          this.filesControl.setValue([]);
          this.uploadedFile.next(null);
          this.loading = false;
        }),
        this.$employee.$serverError.catchErrorAndOpenModal(true),
        catchError((error: string) => {
          this.loading = false;
          return of(error);
        })
      )
      .subscribe();
  }

  private getImage(): void {
    this.filesControl.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((values: Array<File>) => {
          if (FileReader && values[0]) {
            const fileReader = new FileReader();
            fileReader.onload = (event: ProgressEvent<FileReader>): void => {
              this.file = values[0];
              this.uploadedFile.next(event.target.result as string);
            };
            fileReader.readAsDataURL(values[0]);
          } else {
            this.uploadedFile.next(null);
          }
        })
      )
      .subscribe();
  }
}
