<nav
  class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent"
  [ngClass]="{ 'dark-color': environment.machineryGUI }">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      @if (!environment.machineryGUI) {
        <div class="navbar-toggle">
          <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <button
          class="btn btn-outline-light btn-sm btn-toggle mx-2"
          (click)="sidebarToggle()">
          <i class="fas fa-bars"></i>
        </button>
        <a class="navbar-brand" href="javascript:void(0)">{{ getTitle() | translate }}</a>
      } @else {
        <img class="img_logo" src="assets/img/logo_iso.png" />
      }
    </div>
    <button
      class="navbar-toggler"
      type="button"
      (click)="collapse()"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseExample">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>
    <div
      class="collapse navbar-collapse justify-content-end"
      id="collapseExample"
      [ngbCollapse]="isCollapsed">
      <form class="me-3">
        <div class="dropdowns-container-style">
          <div class="input-group no-border">
            <i class="nc-globe nc-icon custom-icon"></i>
            <ngx-select-dropdown
              [instanceId]="'instance1'"
              [multiple]="false"
              [optionItemTemplate]="optionTemplate"
              [selectedItemTemplate]="optionTemplate"
              (change)="selectionChanged($event)"
              [(ngModel)]="companySelected"
              [config]="config"
              [options]="companies"
              [ngModelOptions]="{ standalone: true }"></ngx-select-dropdown>
          </div>
        </div>
      </form>
      <ul class="navbar-nav">
        <li class="nav-item btn-rotate" ngbDropdown display="dynamic">
          <a
            class="nav-link"
            [ngClass]="{ 'text-primary': environment.machineryGUI }"
            ngbDropdownToggle
            id="navbarDropdownMenuLink">
            <i class="nc-icon nc-settings-gear-65"></i>
            <p>
              <span class="d-lg-none d-md-block">{{ 'NAVBAR.CONFIG' | translate }}</span>
            </p>
          </a>
          <div
            ngbDropdownMenu
            aria-labelledby="navbarDropdownMenuLink"
            class="dropdown-menu dropdown-menu-right">
            <div class="d-flex justify-content-center">
              @if (!companyUser || (companyUser && !companyUser.employee?.file)) {
                <img class="profile" src="assets/img/no_profile.png" />
              }
              @if (companyUser && companyUser.employee.file) {
                <img class="profile" [src]="companyUser.employee.file.path" />
              }
            </div>
            <div class="d-flex justify-content-center mt-2">
              @if (companyUser) {
                <label class="d-inline-block text-truncate">{{
                  companyUser.employee.email
                }}</label>
              }
              @if (!companyUser) {
                <label class="d-inline-block text-truncate">{{ userMe?.email }}</label>
              }
            </div>
            <hr />
            @if (companyUser) {
              <a
                ngbDropdownItem
                href="javascript:void(0)"
                (click)="openUserProfileModal()"
                >{{ 'NAVBAR.PROFILE' | translate }}</a
              >
            }
            <a ngbDropdownItem href="javascript:void(0)" (click)="changeLanguage()">{{
              'NAVBAR.CHANGE_LANGUAGE' | translate
            }}</a>
            <a ngbDropdownItem href="javascript:void(0)" (click)="logout()">{{
              'NAVBAR.LOGOUT' | translate
            }}</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<ng-template #optionTemplate let-item="item" let-config="config">
  <i class="nc-icon nc-globe"></i>
  {{ item.name }}
  <span class="new badge"></span>
</ng-template>
